<template>
  <div class="file-container">
    <viewer-details
      :filePath="filePath"
      :line-count="lineCount"
      :size="fileSize"
    ></viewer-details>
    <text-reader :file="rawText" :lineCount="lineCount" :size="fileSize" />
  </div>
</template>

<script>
import TextReader from "../components/FileReader/TextReader.vue";
import { mapState } from "vuex";
import ViewerDetails from "../components/FileReader/ViewerDetails.vue";
export default {
  components: { TextReader, ViewerDetails },
  data() {
    return {
      rawText: null,
      lineCount: 0,
      filePath: [],
      fileSize: 0,
    };
  },
  methods: {
    formatBytes(bytes, decimals = 1) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
  },
  computed: {
    ...mapState({
      file_Viewer: (state) => state.file_Viewer,
      metadata: (state) => state.metadata,
    }),
  },
  mounted() {
    // TODO: call get file in api
    const params = this.$route.params.id;
    this.$store.dispatch("getFileViewer", params);
    this.$store.dispatch("getMetadataByID", {
      type: 2,
      id: params,
      route: this.$route.name,
    });
  },
  watch: {
    file_Viewer: {
      handler(val) {
        const reader = new FileReader();
        reader.readAsText(val, "UTF-8");
        reader.onload = (e) => {
          const { result } = e.target;
          this.fileSize = e.total;
          if (result) {
            this.rawText = result.split("\n");
            this.lineCount = result.split("\n").length;
          }
        };
      },
      deep: true,
    },
    metadata: {
      handler(val) {
        const { path } = val;
        this.filePath = path.split("/");
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.file-container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
</style>
