<template>
  <div class="file-detailBox">
    <div class="file-path">
      <ul>
        <li v-for="(item, index) in filePath" :key="index">{{ item }}</li>
      </ul>
    </div>
    <div class="file-detail">
      <div class="line-count">{{ commaFormater(lineCount) }} line</div>
      <div class="size">{{ humanSize }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filePath: {
      type: Array,
    },
    lineCount: {
      type: Number,
    },
    size: {
      type: Number,
    },
  },
  computed: {
    humanSize: {
      get() {
        return this.formatBytes(this.size);
      },
    },
  },
  methods: {
    formatBytes(bytes, decimals = 1) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    commaFormater: (val) => val.toLocaleString(),
  },
};
</script>

<style lang="scss" scoped>
.file-detailBox {
  background: #f5f5f5;
  margin: 1em;
  border-radius: 4px;
  .file-path {
    border-bottom: 1px solid #4b4b4b;
    padding: 1em;
    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: unset;
      li:not(:last-child)::after {
        display: inline-block;
        margin: 0 0.5em;
        content: ">";
      }
    }
  }
  .file-detail {
    display: flex;
    border-top: 1px solid #4b4b4b;
    padding: 1em 0;
    .line-count {
      padding: 0 1em;
      border-right: 1px solid #4b4b4b;
    }
    .size {
      padding: 0 1em;
      border-left: 1px solid #4b4b4b;
    }
  }
}
</style>
