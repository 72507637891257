<template>
  <div class="content-box">
    <div class="one-linebox" v-for="(item, index) in text" :key="index">
      <div class="line-number">{{ index + 1 }}</div>
      <div class="text">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Array,
    },
  },

  computed: {
    text: {
      get() {
        return this.file;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  margin: 0 1em 1em 1em;
  background: #f5f5f5;
  border-radius: 4px;
  overflow: auto;
  max-height: 80vh;
  .one-linebox {
    display: flex;
    .line-number {
      width: 5%;
      border-right: 1px solid #000;
      background: #ccc;
      text-align: center;
    }
    .text {
      width: 95%;
      margin-left: 1%;
    }
  }
}
</style>
